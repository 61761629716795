
.ContactPage {

  &__personPhoto {

    &--filsakJan {
      background-image: url("../../assets/filsak-jan.jpg");
    }

    &--krivanPavel {
      background-image: url("../../assets/krivan-pavel.jpg");
    }

    &--lapesOndrej {
      background-image: url("../../assets/lapes-ondrej.jpg");
    }

    &--lapesovaSimona {
      background-image: url("../../assets/lapesova-simona.jpg");
    }

    &--bartuLumir {
      background-image: url("../../assets/bartu-lumir.jpg");
    }

    &--dinnebierLadislav {
      background-image: url("../../assets/dinnebier-ladislav.jpg");
    }
  }

  &__infoPanel a,
  &__personInfo a {
    color: var(--color-blue-10);
  }
}
