
.Footer {
  border-top-color: var(--color-grey);
  background: var(--color-grey);
  color: var(--color-text-primary);

  h2,
  p,
  address {
    color: var(--color-text-primary);
  }

  a {
    color: var(--color-yellow);
  }

  &__row {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: $break-large) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: $break-small) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

.Copyright {
  background: var(--color-grey-10);
  color: var(--color-text-primary);

  &__icon {
    background: var(--color-grey-10);

    &:after {
      background: var(--color-grey);
    }
  }

  &__author {
    filter: invert(1);
  }
}
