

.MatchTable {
  margin: 80px 0;

  &__title {
    margin-bottom: 24px;

    &--center {
      text-align: center;
    }
  }

  &__round {
    background: var(--color-grey);
    border-left: 1px solid var(--color-grey-10);
    border-right: 1px solid var(--color-grey-10);
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $font-primary;
    padding: 8px;
    font-weight: bold;
    color: var(--color-text-secondary);
    padding-right: 24px;

    &:first-child {
      border-top: 1px solid var(--color-grey-10);
    }
  }

  &__phaseName {
    display: flex;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-text-primary);
    font-weight: bold;
    background: var(--color-blue-overlay-04);
    padding: 4px;
    border-radius: 4px;
  }
}
