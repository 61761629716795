

.PersonHeader__title {
  color: var(--color-white);

  &:before {
    background: url("~@amf/shared/assets/icon-cross-white.svg") no-repeat center;
    background-size: 24px 24px;
  }
}

.PersonHeader__card {
  background: var(--color-white);
  color: var(--color-text-primary);
}

.PersonHeader__content ul {
  background: var(--color-black-08);
}

.PersonHeader__content ul li label {
  color: var(--color-text-primary)
}
