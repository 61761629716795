// Vendor
@import "~normalize-scss/sass/normalize";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";
@import "~tippy.js/animations/shift-away.css";

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Main
@import "variables";
@import "mixins";
@import "typography";

// Icons
@import "icons";

// Components
@import "components/layout/layout";
@import "components/layout/container";
@import "components/layout/footer";
@import "components/layout/copyright";
@import "components/layout/grid";
@import "components/layout/header";
@import "components/layout/table";

@import "components/button/button";
@import "components/button/buttonIcon";

@import "components/form/field";
@import "components/form/form";
@import "components/form/filter";

@import "components/menu/menu";
@import "components/menu/dropdown";
@import "components/menu/tabs";

@import "components/content/content";
@import "components/navigation/navigation";
@import "components/error/error";

@import "components/news/newsListItem";
@import "components/news/newsPreviewList";

@import "components/slider/slider";

@import "components/utils/loader";
@import "components/utils/empty";
@import "components/utils/pulser";
@import "components/utils/banner";

@import "components/player/playerHeader";
@import "components/player/playerListItem";
@import "components/player/playerList";
@import "components/player/transfers";
@import "components/player/info";
@import "components/player/news";
@import "components/player/stats";
@import "components/player/lastMatches";
@import "components/player/unions";

@import "components/season/listItem";

@import "components/league/listItem";
@import "components/league/header";
@import "components/league/teamTable";
@import "components/league/matchSlider";
@import "components/league/advancedStats";

@import "components/team/avatar";
@import "components/team/header";
@import "components/team/roster";
@import "components/team/contact";
@import "components/team/matches";
@import "components/team/table";
@import "components/team/list";
@import "components/team/comparison";
@import "components/team/stats";
@import "components/team/playerStats";

@import "components/club/header";
@import "components/club/list";
@import "components/club/item";

@import "components/match/table";
@import "components/match/brackets";
@import "components/match/header";
@import "components/match/headerAlt";
@import "components/match/toolbar";
@import "components/match/roster";
@import "components/match/rosterList";
@import "components/match/events";
@import "components/match/event";
@import "components/match/item";
@import "components/match/player";
@import "components/match/previous";
@import "components/match/contumacy";
@import "components/match/playoff";
@import "components/match/jumbotron";

@import "components/dashboard/match";
@import "components/dashboard/player";
@import "components/dashboard/league";

@import "components/stats/player";

@import "components/ground/list";
@import "components/ground/item";
@import "components/ground/header";
@import "components/ground/info";
@import "components/ground/photos";

@import "components/referee/list";
@import "components/person";

@import "components/search/search";
@import "components/search/advancedSearch";

@import "components/contract/index";
@import "components/page/header";

@import "components/offense/index";

@import "components/ad/ad";

// Pages
@import "pages/notFoundPage";
@import "pages/contactPage";
@import "pages/documentsPage";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
