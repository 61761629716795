

.AdvancedSearch {


  &__header:after {
    background: var(--color-yellow);
  }

  h1 {
    color: var(--color-white);
  }

  h1:before {
    background: url("~@amf/shared/assets/icon-cross-white.svg");
    background-size: 24px 24px;
  }
}
